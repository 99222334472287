<template>
  <loader v-if="showloader == true"></loader>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-450px ps-15"
            placeholder="Recherche"
            @change="searchdata"
            v-model="searchValue"
          />
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_maj_sequence"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
          
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  text-uppercase
                "
              >
                <th>#</th>
                <th>
                  Question <i class="fa fa-sort" @click="sort('name')" style="cursor:pointer !important;"></i>
                </th>
                <th>date <i class="fa fa-sort" @click="sort('created_at')" style="cursor:pointer !important;"></i></th>
                <th>Liste Réponse</th>
                <th colspan="2">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in AllQuizz" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="odd">{{ date_format(item.created_at) }}</td>
                <td v-if="item.quizzcontent">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_ListeQuizz"
                    @click="AffichelisteQuizz(item.quizzcontent)"
                    style="cursor: pointer; color: #009ef7"
                  >
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style="font-weight: bold; font-size: 18px"
                    ></i>
                  </span>
                </td>
                <td v-else>&nbsp;</td>
                <td v-if="item.isActive == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td v-if="item.isActive == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="end">
                  <span class="action_icon"
                    ><i
                      class="fa fa-edit fa-2x"
                      @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_maj_sequence"
                    ></i
                    >Edit</span
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row" style="margin-right: 0">
            <div
              class="
                col-sm-2 col-md-2
                d-flex
                align-items-center
                justify-content-center justify-content-md-start
              "
            >
              <label
                ><select
                  class="
                    form-select form-select-sm form-select-solid
                    select_page
                  "
                  @change="searchdata"
                  v-model="limit"
                  style="cursor: pointer"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100000">All</option>
                </select></label
              >
            </div>
            <div
              class="
                col-sm-10 col-md-6
                d-flex
                align-items-center
                justify-content-center justify-content-md-end
              "
            >
              <paginate
                :page-count="totalpage"
                :page-range="3"
                :margin-pages="2"
                :click-handler="clickCallback"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- *****************modal ajouter question ***************** -->
  <div
    class="modal fade bd-example-modal-lg"
    tabindex="-1"
    id="kt_modal_maj_sequence"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ajouter Question</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Question </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <!-- <div class="col-md-6">
                <label for="thematique" class="form-label required"
                  >Offre 
                </label>
                <select
                  class="form-select"
                  id="region"
                  name="region"
                  aria-label="Default select example"
                  v-model="add_data.offre_id"
                >
                  <option
                    v-for="offre in AllOffres"
                    :key="offre.id"
                    :value="offre.id"
                  >
                    {{ offre.name }}
                  </option>
                </select>
              </div> -->
              <div class="col-md-6">
                <label for="isActive" class="form-label required"
                  >Status
                </label>
                <select class="form-select m-2" v-model="add_data.isActive">
                  <option value="1">Activer</option>
                  <option value="0">Desactiver</option>
                </select>
              </div>
            </div>
            <hr />
            <div class="row">
              <label class="form-label">Image présentation : </label>
              <input
                class="custom-file-input"
                type="file"
                @change="uploadFile"
                ref="file"
              />
            </div>
            <div class="row" style="margin-top: 1%; width: 25%">
              <img v-if="mediaquestion" :src="pathImg + '/' + mediaquestion" />
            </div>
            <hr />
            <div class="row">
              <div class="col">
                <label for="isActive" class="form-label required"
                  >Reponse
                </label>
                <button
                  style="float: right"
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="add_reply()"
                >
                  Ajouter
                </button>
              </div>
              <div class="col" style="text-align: center">
                <label for="isActive" class="form-label required"
                  >Bonne reponse
                </label>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div class="row">
              <div
                class="row"
                v-for="item in reply"
                :key="item.index"
                style="margin-top: 1%"
              >
                <div class="col-md-8">
                  <input
                    v-model="item.name"
                    type="text"
                    class="form-control"
                    id="flexCheckDefault"
                  />
                </div>
                <div class="col-md-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="item.value"
                    true-value="1"
                    false-value="0"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- *****************modal Liste Reponse quizz***************** -->
  <div class="modal fade" tabindex="-1" id="kt_modal_ListeQuizz">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Liste Réponse</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataQuizz()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>#</th>
                <th>Réponse</th>
                <th>Bonne Réponse</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, index) in reply" :key="r.id">
                <td>{{ index + 1 }}</td>
                <td>{{ r.name }}</td>
                <td class="odd" v-if="r.value == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="odd" v-if="r.value == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetDataQuizz()"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiService } from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Editor from "@tinymce/tinymce-vue";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import loader from "../utility/loader.vue";
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    loader,
    Paginate,
  },
  data() {
    return {
      Images: null,
      AllQuizz: {},
      add_data: {
        isActive: 1,
        id: 0,
      },
      selectThematique: {},
      AllOffres: {},
      reply: [
        {
          name: "",
          value: "0",
        },
      ],
      formMode: "ajouter",
      pathImg: "",
      mediaquestion: "",
      showloader: false,

      totalpage: 0,
      currentpage: 1,
      limit: 10,
      sum: 0,
      offset: 0,
      searchValue: "",
      orderStatus: "asc",
    };
  },
  methods: {
    sort(index) {
      if (this.orderStatus === 'asc') {
          this.orderStatus = 'desc';
          this.AllQuizz = this.AllQuizz.sort((a, b) => {
            return b[index].localeCompare(a[index]);
          });
      } else if (this.orderStatus === 'desc') {
          this.orderStatus = 'asc';
          this.AllQuizz = this.AllQuizz.sort((a, b) => {
            return a[index].localeCompare(b[index]);
          });
      };
    },
    date_format(value) {
      return moment(String(value)).format("MM/DD/YYYY hh:mm");
    },
    resetDataQuizz() {
      this.reply = [
        {
          name: "",
          value: "0",
        },
      ];
    },
    async AffichelisteQuizz(data) {
      this.reply = data;
    },
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },
    alertSucess() {
      Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        location.reload();
      });
    },
    // getQuizzContent(id) {
    //   ApiService.setHeader();
    //   ApiService.get("tests/quizzContent/" + id)
    //     .then(async (result) => {
    //       this.reply = await result.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // getListeOffre() {
    //   ApiService.setHeader();
    //   ApiService.get("formation/offre")
    //     .then(async (result) => {
    //       this.AllOffres = await result.data;
    //       console.log("******* afficher liste offre *******");
    //       console.log(this.AllOffres);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    submitFile(id) {
      if (id) {
        const formData = new FormData();
        formData.append("file", this.Images);
        formData.append("id", id);
        const headers = { "Content-Type": "multipart/form-data" };
        ApiService.post("tests/quizzquestion/UploadFile", formData, {
          headers,
        }).then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          this.showloader = false;
          this.alertSucess();
        });
      }
    },
    submit() {
      this.showloader = true;
      //if (this.add_data.offre_id > 0) {
      this.add_data["listeReply"] = this.reply;
      this.add_data["formMode"] = this.formMode;

      ApiService.post("tests/quizzquestion/InsertOrUpdate", this.add_data)
        .then((response) => {
          if (this.Images) {
            this.submitFile(response.data);
          } else {
            this.showloader = false;
            this.alertSucess();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
      //}
    },
    getListeQuizzquestion() {
      this.showloader = true;
      this.offset = this.limit * (this.currentpage - 1);
      ApiService.setHeader();
      ApiService.query("tests/quizzquestion", {
        params: {
          searchValue: this.searchValue,
          limit: this.limit,
          offset: this.offset,
        },
      })
        .then(async (result) => {
          this.AllQuizz = await result.data.liste;
          this.sum = await result.data.sum;
          this.initpage();
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add_reply() {
      this.reply.push({
        name: "",
        value: "0",
      });
    },
    async maj(item, val) {
      this.formMode = val;
      this.resetData();
      if (val == "editer") {
        this.reply = item.quizzcontent;
        this.add_data = await item;
        const media = await item.mediaquestion[0];
        if (media) {
          this.mediaquestion = await media.path;
        }
      }
    },
    resetData() {
      this.reply = [
        {
          name: "",
          value: "0",
        },
      ];
      this.Images = null;
      this.add_data = {
        isActive: 1,
        id: 0,
      };
    },
    async getpathImg() {
      this.pathImg = await process.env.VUE_APP_FILE;
    },
    clickCallback(pageNum) {
      this.currentpage = pageNum;
      this.getListeQuizzquestion();
    },
    async initpage() {
      this.totalpage = await Math.ceil(this.sum / this.limit);
    },
    async searchdata() {
      this.currentpage = await 1;
      this.getListeQuizzquestion();
    },
  },
  mounted() {
    //this.getListeOffre();
    this.getListeQuizzquestion();
    this.getpathImg();
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">
</style>